// JavaScript Document


jQuery( document ).ready(function($) {
    
	$(document).foundation();
	
	
	
	$('.hamburger').on('click', function(){
		
		$('.hamburger').toggleClass('is-active');
		$('.mobile-menu').toggle();
		$('html').toggleClass('locked');

	});
		$(window).scroll(function() {
		 if ($(this).scrollTop() > 250)
		 {
		  $('.sticky-header').addClass("active");
		 }
		 else
		 {
		  $('.sticky-header').removeClass("active");
		 }
		});
	//SLIDER
	$('.slider').on('moved.zf.slider', function(){
		
		if($('#sliderOutput1').val() > 95) {
			$('.slider-handle').css('background-color','#8bba34');
			$('#contact_form .button1').removeAttr("disabled");
			
		} else {
			$('.slider-handle').css('background-color','#656766');
			$('#contact_form .button1').attr("disabled", "true");
		}
	});
	
	//ACCORDION MOBILE MENU
	$('.is-accordion-submenu-parent a').click(function(){
        $(this).unbind('click');
	});
	window.sr = ScrollReveal();

// Customizing a reveal set
sr.reveal('.rvl', { 
	duration: 500,
    rigin: 'bottom',
    scale:1,
    delay:500
	});
sr.reveal('.str', { 
	duration: 500,
	origin: 'bottom',
	scale: 1
}, 70);
sr.reveal('.cd-timeline-content', {
	duration: 500,
	origin: 'bottom',
	scale: 1,
    distance: '100px',
    delay:500
});
    
    //NEWSLETTER FORM
    $(".contact-button").click(function() {
        $('.hamburger').removeClass('is-active');
        $('.mobile-menu').hide();
        $('html').removeClass('locked');
        $('html, body').animate({
            scrollTop: $("#contact-section").offset().top
        }, 1500);
        
      
});
});
